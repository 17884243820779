// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Footer = () => (

  <footer>
    
      <div class="mx-auto max-w-screen-xl items-end text-xs text-center">
        Made by <a href="https://www.yanngraf.com">Yann Graf</a>
      </div>
   
  </footer>
    
)

export default Footer