/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import {Helmet} from 'react-helmet';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, backgroundColor }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
        <Helmet>
          <style>{'body { background-color: '+backgroundColor+'; }'}</style>
        </Helmet>
        
        <div class="w-full md:hidden p-8 bg-gray-400">Best viewed on desktop <span role="img" aria-label="firecracker">🧨</span></div>


        <div class="flex flex-wrap p-8 h-screen" >
          <div class="w-full mb-10">
            <Header siteTitle={data.site.siteMetadata.title} />
          </div>
          <div class="w-full flex-grow mx-auto max-w-screen-xl">
            <main>{children}</main>
          </div>
          <div class="w-full self-end m-1">
            <Footer />
          </div>
        </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
